<template>
  <div>
    <DisplayTotalization title="Total" :value="totalization" />
  </div>
</template>
<script>

import DisplayTotalization from "@nixweb/nixloc-ui/src/component/rental/DisplayTotalization";

import { mapActions, mapState } from "vuex";

export default {
  name: "NfeTotalization",
  components: { DisplayTotalization },
  props: {
    nfeId: String,
  },
  data() {
    return {
      urlGetTotalization: "/api/v1/tax-document/nfe/totalization",
      totalization: 0,
    };
  },
  created() {
    this.getTotalization();
  },
  computed: {
    ...mapState("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getTotalization() {
      let params = { url: this.urlGetTotalization, obj: { nfeId: this.nfeId } };
      this.getApi(params).then((response) => {
        this.totalization = response.content;
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "updateTotalizationOrderService")
          this.getTotalization();
      },
      deep: true,
    },
  },
};
</script>
